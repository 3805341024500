<template>
    <div class="container">
        <modal-add-edit-absence modalId="modalEditAbsence" ref="modalEditAbsence" title="Izmena odsustva" mode="EDIT" :absence.sync="editedAbsence" @confirmed="updateAbsence"></modal-add-edit-absence>
        <modal-confirm @confirm="deleteSelectedAbsence" inputId="confirmModal" title="Potvrda brisanja odsustva" message="Da li ste sigurni da želite da obrišete odustvo?"/>

            <div class="d-inline-flex">
            <h4>Odustva u preiodu: </h4>
                <div class="input-group mb-3 partialy-input-group">
        
                    <input type="date" class="form-control" :max="dateUpper" v-model="dateLower" aria-label="Od" @change="dateChanged">
                    <span class="input-group-text">-</span>
                    <input type="date" class="form-control" :min="dateLower" v-model="dateUpper" aria-label="Do" @change="dateChanged">
                </div>

            </div>

        <div>
            <ejs-grid
                ref="absenceRequests"
                :dataSource="absences" 
                class="absence-requests-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='false' 
                :pageSettings='pageSettings'
                :recordDoubleClick="absenceDBClick"
                :toolbarClick='clickHandler'
                :toolbar='toolbar'
                :allowResizing='true'
                :queryCellInfo='customiseCell'
                :allowTextWrap='true'

                >
                <e-columns>

                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>

                    <e-column field='user.username' headerText='Ime i Prezime' width='120' textAlign='Left' :template="usernameTemplate"></e-column>
                    
                    <!-- <e-column field='employee.first_name' headerText='Ime' width='120' textAlign='Left'></e-column>
                    <e-column field='employee.last_name' headerText='Prezime' width='120'></e-column> -->

                    <e-column field='start_date' :valueAccessor='dateAccessor' headerText='Početak' width='100' editType='datepickeredit' textAlign='Right' :visible='true'></e-column>
                    <e-column field='end_date' :valueAccessor='dateAccessor' headerText='Kraj' width='100' editType='datepickeredit' format="yMd" textAlign='Right' :visible='true'></e-column>
                    <e-column field='absence_type' :valueAccessor='absenceTypeAccessor' headerText='Razlog' width='120' editType='dropdownedit'></e-column>
                    <e-column field='absence_status' :valueAccessor='absenceStatusAccessor' headerText='Status' width='200' editType='dropdownedit'></e-column>
                    <e-column field='comment' headerText='Komentar' width='150'></e-column>

                    <e-column field='approved_at' :valueAccessor='dateTimeAccessor' headerText='Obradjeno' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='approved_by.username' :valueAccessor='fullnameAccessor' headerText='Obradio' width='130' :visible='false'></e-column>

                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='true'></e-column>
                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Izmenjeno' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' :valueAccessor='fullnameAccessor' headerText='Napravio zahtev' width='130' :visible='false'></e-column>


                </e-columns>
            </ejs-grid> 
        </div>
    </div>
</template>

<script>

    import AbsencesService from '@/service/AbsencesService';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize } from "@syncfusion/ej2-vue-grids";
    import UtilService from '@/service/UtilService.js';
    import { Modal } from 'bootstrap';
    import EmployeesService from '@/service/EmployeesService';

    import ModalConfirm from '@/components/modals/ModalConfirm.vue';
    import ModalAddEditAbsence from '@/components/modals/ModalAddEditAbsence';

    import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';

    export default {
        components: { ModalAddEditAbsence, ModalConfirm },


        data() {
            return {
                absences: null,

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },

                toolbar: [{ text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editAbsence'}, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteAbsence'}],

                modalEditAbsebce: null,
                confirmModal: null,

                editedAbsence: null,

                dateLower: UtilService.formatDatePattern(UtilService.getMoment()),
                dateUpper: UtilService.formatDatePattern(UtilService.getMoment().add(15, 'day')),

                usernameTemplate: function() {
                    return {
                        template: {
                            extends: UsernameTemplate,
                            propsData: {
                                showAvatar: true,
                                showName: true,
                                showLastName: true,
                                showUsername: false,
                                profilePage: 'EmployeeAbsences',
                                dataLocation: 'employee',
                                
                            },
                        }

                    };
                },

                

            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize ]
        },


        methods: {
            
            formatDate: UtilService.formatDate,
            formatDateTime: UtilService.formatDateTime,
        
            fullnameAccessor(field, data) {
                let firstField = field.split('.')[0];
                let userData = data?.[firstField];
                console.log(userData);
                if (!userData?.first_name) return;
                return userData.first_name + " " + userData.last_name; 
            },

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },
            dateAccessor(field, data) {
                return data[field] ? this.formatDate(data[field]) : '/';
            },
            absenceTypeAccessor(field, data) {
                return data[field] ? UtilService.translateAbsenceType(data[field]) : '/';
            },
            absenceStatusAccessor(field, data) {
                let approved = data.approved_by?.username;


                let status = data[field] ? UtilService.translateAbsenceStatus(data[field]) : '/';
                if (approved)
                    return status + " ( " + approved + " )"
                else return status;
            },


            async loadAbsences() {


                let data = {
                    'start_date': this.dateLower,
                    'end_date': this.dateUpper
                }

                try {
                    let result = await AbsencesService.getAbsencesInRange(data)
                    this.absences = result.data;
                    this.refresh()

                } catch (error) {
                    console.log(error);
                    alert("greska prilikom ucitavanja odsustva");
                }

            },

            absenceDBClick(event) {
                this.editedAbsence = event.rowData;
                this.showEditAbsenceModal();
            },

            
            clickHandler(args) {

                if (args.item.id === "editAbsence"){
                    let selected = (this.$refs.absenceRequests.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.editedAbsence = selected;

                    this.showEditAbsenceModal();
                }

                if (args.item.id === "deleteAbsence"){
                    let selected = (this.$refs.absenceRequests.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;
                    
                    this.confirmModal.show();
                }
            },

            
            async updateAbsence() {
                try {
                    this.editedAbsence.employee_id = this.editedAbsence.employee.user.id;
                    let result = await EmployeesService.postAbsence(this.editedAbsence)
                    this.editedAbsence = result.data;
                    this.modalEditAbsebce.hide();   
                    this.loadAbsences();
                    this.toast('Uspešno izmenjeno odsustvo.');
            } catch (error) {
                    console.log(error)
                    alert("Neuspesno")
                }
            },

            showEditAbsenceModal() {
                this.$refs.modalEditAbsence.load();
                this.modalEditAbsebce.show();
            },
            

            deleteSelectedAbsence(){
                    let selected = (this.$refs.absenceRequests.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.deleteAbsence(selected)
            },

            dateChanged() {
                this.loadAbsences();
            },

            refresh() {
                this.$refs.absenceRequests.ej2Instances.refresh();
            },

                
            customiseCell(args) {
                    let status = args.data?.absence_status;
                    if (status == "REQUESTS")
                        args.cell.classList.add('abs-requestd');
                    else if (status == "APPROVED")
                        args.cell.classList.add('abs-approved');
                    else if (status == "REJECTED")
                        args.cell.classList.add('abs-rejected');
                    else if (status == "ADMIN_CHANGE")
                        args.cell.classList.add('abs-change');
                    
            },

            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            },
        },

        

        mounted() {
            this.modalEditAbsebce = new Modal(document.getElementById('modalEditAbsence'));
            this.confirmModal = new Modal(document.getElementById('confirmModal'));
        },

        created() {
            this.loadAbsences();
        }
    }
</script>

<style>

    .absence-requests-grid table .abs-approved {
        background: #0dff00;
    }
    .absence-requests-grid table tr:hover .abs-approved {
        background: #0cdd01 !important;
    }


    .absence-requests-grid table .abs-rejected {
        background: #961600;
    }
    .absence-requests-grid table tr:hover .abs-rejected {
        background: #7b1200 !important;
    }

    .absence-requests-grid table .abs-change {
        background: #00f2ff;
    }
    .absence-requests-grid table tr:hover .abs-change {
        background: #00afb8 !important;
    }

    .absence-requests-grid table .abs-requested {
        background: #ff6449;
    }
    .absence-requests-grid table tr:hover .abs-requested {
        background: #e9583e !important;
    }
</style>